import { useMemo } from 'react';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import _ from 'lodash';
import { getApiBase } from './utils';

let apolloClient;

function createApolloClient() {
  const httpLink = createHttpLink({
    uri: getApiBase(),
  });

  const authLink = setContext((request, { headers }) => {
    const token = localStorage.getItem('token');
    const authorizationHeader =
      headers && _.has(headers, 'authorization')
        ? headers.authorization
        : token
          ? `Bearer ${token}`
          : '';
    return {
      headers: {
        ...headers,
        authorization: authorizationHeader,
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    // @ts-ignore
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        UserRecord: {
          fields: {
            data: {
              merge: false,
            },
          },
        },
        BusinessRecord: {
          fields: {
            data: {
              merge: false,
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}

export const NO_AUTHORIZATION_HEADER = {
  headers: {
    authorization: '',
  },
};
