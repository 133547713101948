import React from 'react';
import FacebookLogo from '../components/icons/FacebookLogo';
import InstagramLogo from '../components/icons/InstagramLogo';
import LinkedInLogo from '../components/icons/LinkedInLogo';
import TwitterLogo from '../components/icons/TwitterLogo';

export function getApiBase() {
  //@ts-ignore
  const url = process.env.isLocal ? 'http://localhost:8081/graphql' : '/graphql';

  return url;
}

export function getSocialIconForPlatform(platform) {
  switch (platform) {
    case 'facebook':
      return <FacebookLogo />;
      break;
    case 'instagram':
      return <InstagramLogo />;
    case 'linkedin':
      return <LinkedInLogo />;
    case 'twitter':
      return <TwitterLogo />;
    default:
      return null;
      break;
  }
}
