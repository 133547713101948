import React from 'react';

import styles from './social-icon.module.sass';

const FacebookLogo = () => (
  <svg
    className={styles.container}
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="54"
    viewBox="0 0 54 54"
  >
    <path d="M23.5,22.3333333 L20,22.3333333 L20,27 L23.5,27 L23.5,41 L29.3333333,41 L29.3333333,27 L33.5823333,27 L34,22.3333333 L29.3333333,22.3333333 L29.3333333,20.3885 C29.3333333,19.2743333 29.5573333,18.8333333 30.6341667,18.8333333 L34,18.8333333 L34,13 L29.5573333,13 C25.362,13 23.5,14.8468333 23.5,18.3841667 L23.5,22.3333333 Z" />
  </svg>
);

export default FacebookLogo;
