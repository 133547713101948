import React from 'react';

import styles from './social-icon.module.sass';

const TwitterLogo = () => (
  <svg
    className={styles.container}
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="54"
    viewBox="0 0 54 54"
  >
    <path d="M43,17.1 C41.8,17.6 40.6,18 39.2,18.1 C40.6,17.3 41.6,16 42.1,14.5 C40.8,15.3 39.4,15.8 37.9,16.1 C36.7,14.8 35,14 33.2,14 C29.6,14 26.6,16.9 26.6,20.6 C26.6,21.1 26.7,21.6 26.8,22.1 C21.3,21.8 16.5,19.2 13.3,15.2 C12.7,16.2 12.4,17.3 12.4,18.5 C12.4,20.8 13.6,22.8 15.3,24 C14.2,24 13.2,23.7 12.3,23.2 C12.3,23.2 12.3,23.3 12.3,23.3 C12.3,26.5 14.6,29.1 17.6,29.7 C17,29.8 16.5,29.9 15.9,29.9 C15.5,29.9 15.1,29.9 14.7,29.8 C15.5,32.4 18,34.3 20.8,34.4 C18.6,36.2 15.7,37.2 12.6,37.2 C12.1,37.2 11.5,37.2 11,37.1 C13.9,39 17.4,40 21.1,40 C33.2,40 39.8,30 39.8,21.3 C39.8,21 39.8,20.7 39.8,20.5 C41,19.5 42.1,18.4 43,17.1 Z" />
  </svg>
);

export default TwitterLogo;
