import * as React from 'react';
import Head from 'next/head';

interface Props {
  title?: string;
  containerClass?: string;
  children: any;
}

const PageWrapper = ({ title, containerClass, children }: Props) => (
  <main className={`page ${containerClass ? containerClass : ''}`}>
    <Head>
      <title>
        {title && title.length > 0
          ? `${title} - Fifteen Percent Pledge`
          : 'Fifteen Percent Pledge'}
      </title>
    </Head>
    {children}
  </main>
);

export default PageWrapper;
