//@flow
import React, { useState } from 'react';
import LoginEmailForm from './LoginEmailForm';
import ResetPasswordForm from './ResetPasswordForm';
import styles from './login.module.sass';

type Props = {
  showHeader?: boolean;
  light?: boolean;
};

enum ViewState {
  LOGIN,
  FORGOT,
}

const UserLogin = ({ showHeader, light }: Props) => {
  const [viewState, setViewState] = useState(ViewState.LOGIN);

  const handleForgotPassword = () => {
    setViewState(ViewState.FORGOT);
  };

  return (
    <div className={`center column ${styles.container}`}>
      {viewState === ViewState.LOGIN && (
        <>
          {showHeader && <h3 className={styles.header}>Sign In</h3>}
          <LoginEmailForm onForgot={handleForgotPassword} light={light} />
        </>
      )}
      {viewState === ViewState.FORGOT && (
        <>
          <h3 className={styles.header}>Reset Password</h3>
          <ResetPasswordForm />
        </>
      )}
    </div>
  );
};

export default UserLogin;
