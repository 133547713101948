import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { NO_AUTHORIZATION_HEADER } from '../../helpers/apolloClient';
import { M_LOGIN } from '../../graphql/user';
import { IToken, ILoginEmail } from '../../types/user';
import { useAuth, useModal } from '../../hooks';
import { ModalTypes } from '../../context/modalContext';
import FormTextField from '../form/ui/FormTextField';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './login.module.sass';

type Props = {
  onForgot: () => void;
  light: boolean;
};

const LoginEmailForm = ({ onForgot, light }: Props) => {
  const { setUserToken } = useAuth();
  const { setModalType } = useModal();

  const LoginEmailFormSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter your email address'),
    password: Yup.string().required('Please enter your password'),
  });

  const [loginUser, { loading, error }] = useMutation<IToken, ILoginEmail>(M_LOGIN, {
    onCompleted(data: any) {
      const token = data.login.token;
      if (token) setUserToken(token);
    },
    onError(error: any) {
      console.log('login error: ', error.message);
    },
  });

  const handleFormSubmit = async (values: any) => {
    await loginUser({
      variables: {
        email: values.email,
        password: values.password,
      },
      context: NO_AUTHORIZATION_HEADER,
    });
  };

  const handleRegister = () => {
    setModalType(ModalTypes.REGISTER);
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginEmailFormSchema}
        onSubmit={handleFormSubmit}
      >
        <Form className={styles.form}>
          <FormTextField
            type="email"
            name="email"
            label="Email Address"
            usePlaceholder
            light={light}
          />
          <FormTextField
            type="password"
            name="password"
            label="Password"
            usePlaceholder
            light={light}
          />
          <button
            type="button"
            className={`btn-inline hint ${styles.end}`}
            onClick={onForgot}
          >
            Forgot Password?
          </button>
          <button
            type="submit"
            className={`full-width button ${light ? 'secondary' : 'primary'}`}
          >
            Log In
          </button>
          <p className="full-width hint text-center">
            Don{"'"}t have an account yet?{' '}
            <button type="button" className="btn-inline" onClick={handleRegister}>
              Register
            </button>
          </p>
        </Form>
      </Formik>
      {loading && <LoadingIndicator />}
      {error && <p className={styles.error}>{error.message}</p>}
    </>
  );
};

export default LoginEmailForm;
