import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { M_REQUEST_RESET_PASSWORD } from '../../graphql/user';
import { IResetEmail } from '../../types/user';
import ErrorMessageComponent from '../form/ErrorMessageComponent';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './login.module.sass';

const ResetPasswordForm = () => {
  const [requestSent, setRequestSent] = useState(false);
  const ResetPasswordFormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Required'),
  });

  const [resetPassword, { loading, error }] = useMutation<IResetEmail>(
    M_REQUEST_RESET_PASSWORD,
    {
      onCompleted() {
        setRequestSent(true);
      },
      onError(error: any) {
        console.log('reset error: ', error.message);
      },
    }
  );

  const handleFormSubmit = (values: any) => {
    resetPassword({
      variables: {
        email: values.email,
      },
    });
  };

  if (requestSent) {
    return (
      <div className={`full-width row center ${styles.container}`}>
        <p>
          We{"'"}ve received your request, please check your email to reset your
          password
        </p>
      </div>
    );
  }

  return (
    <div className={`center column ${styles.container}`}>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={ResetPasswordFormSchema}
        onSubmit={handleFormSubmit}
      >
        <Form className={`center ${styles.form}`}>
          <label className="label" htmlFor="email">
            Enter your email address
          </label>
          <Field id="email" name="email" placeholder="Email" type="email" />
          <ErrorMessage
            name="email"
            render={(msg) => <ErrorMessageComponent error={msg} />}
          />

          <button type="submit" className={`button primary ${styles.btnreset}`}>
            Reset Password
          </button>
          {loading && <LoadingIndicator />}
          {error && <p className={styles.error}>{error.message}</p>}
        </Form>
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
