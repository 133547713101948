import React from 'react';

import styles from './social-icon.module.sass';

const LinkedInLogo = () => (
  <svg
    className={styles.container}
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="54"
    viewBox="0 0 54 54"
  >
    <path d="M35.1666667,13 L18.8333333,13 C15.6121667,13 13,15.6121667 13,18.8333333 L13,35.1666667 C13,38.3878333 15.6121667,41 18.8333333,41 L35.1666667,41 C38.389,41 41,38.3878333 41,35.1666667 L41,18.8333333 C41,15.6121667 38.389,13 35.1666667,13 Z M22.3333333,35.1666667 L18.8333333,35.1666667 L18.8333333,22.3333333 L22.3333333,22.3333333 L22.3333333,35.1666667 Z M20.5833333,20.854 C19.4563333,20.854 18.5416667,19.9323333 18.5416667,18.796 C18.5416667,17.6596667 19.4563333,16.738 20.5833333,16.738 C21.7103333,16.738 22.625,17.6596667 22.625,18.796 C22.625,19.9323333 21.7115,20.854 20.5833333,20.854 Z M36.3333333,35.1666667 L32.8333333,35.1666667 L32.8333333,28.6286667 C32.8333333,24.6993333 28.1666667,24.9968333 28.1666667,28.6286667 L28.1666667,35.1666667 L24.6666667,35.1666667 L24.6666667,22.3333333 L28.1666667,22.3333333 L28.1666667,24.3925 C29.7953333,21.3755 36.3333333,21.1526667 36.3333333,27.2811667 L36.3333333,35.1666667 Z" />
  </svg>
);

export default LinkedInLogo;
